import React from 'react'
import GlobalStyle from '../components/GlobalStyle'
import styled from 'styled-components'
import { H1 } from '../components/layout/headers/Headers'
import { graphql } from 'gatsby'
import ResultCard from '../components/resultCard/ResultCard'
import DropdownSelector from '../components/dropdownSelector/DropdownSelector'
import Menubar from '../components/menuBar/Menubar'
import { device } from '../utils/responsive'
import ButtonContainer from '../components/buttonComponents/ButtonContainer'
import Seo from '../components/seo/Seo'
import { useLoansJson } from '../hooks/useLoansJson'
import { useLoanCompareMd } from '../hooks/useLoanCompareMd'
import { Content } from '../components/layout/content/Content'

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebarRatio};
  @media ${device.mobileL} {
    grid-template-columns: 100%;
  }
`

const MainArea = styled.div`
  background-color: ${props => props.theme.bgContainer};
  padding: 2rem;
  margin: 1rem;
  text-align: left;
  @media ${device.mobileL} {
    padding: 0.22rem;
    margin: 0.1rem;
  }
`

const Sidebar = styled.div`
  background-color: ${props => props.theme.bgContainer};
  margin: 1rem;
`

const Template = ({ data }) => {
  let { markdownRemark: post } = data
  post = Object.assign({}, post, post.fields, post.frontmatter)
  const pageData = data.markdownRemark
  const loanSums = useLoanCompareMd()
  const loans = useLoansJson(null, pageData.frontmatter.loanSum)
  return (
    <div>
      <Seo isBlogPost={true} postData={post} postImage={post.img} />
      <GlobalStyle>
        <Menubar />
        <H1>{pageData.fields.newTitle}</H1>
        <ContentContainer>
          <MainArea>
            {loans.map(n => (
              <ResultCard data={n} key={n.node.id} />
            ))}
            <Content data={data.markdownRemark} />
            <ButtonContainer
              filter="loancompare"
              data={loanSums}
              currentTitle={pageData.frontmatter.title}
            />
          </MainArea>
          <Sidebar>
            <DropdownSelector
              current={pageData.frontmatter.loanSum}
              sidebar={true}
            />
          </Sidebar>
        </ContentContainer>
      </GlobalStyle>
    </div>
  )
}

export const query = graphql`
  query($pathFilter: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathFilter } }) {
      htmlAst
      id
      excerpt
      frontmatter {
        title
        loanSum
        desc
        img
        metaDesc
        metaTitle
        type
      }
      fields {
        newPath
        newTitle
        newMetaTitle
        newMetaDesc
      }
      headings {
        value
        depth
      }
    }
  }
`

export default Template
