export const imageData = {
  ferratum: 'ferratum',
  vivus: 'vivus',
  credit24: 'credit24',
  'limiitti.fi': 'limiitti',
  limiitti: 'limiitti',
  'bank norwegian': 'banknorwegian',
  'fellow finance': 'fellowfinance',
  'ferratum business': 'ferratumbusiness',
  yritysluotto: 'yritysluotto',
  suomilimiitti: 'suomilimiitti',
  rahapalvelu: 'rahapalvelu',
  'komplett bank': 'komplettbank',
  eurolimiitti: 'eurolimiitti',
  'flexiluotto.fi': 'vivusjousto',
  risicum: 'risicum',
  'euro24.fi': 'euro24',
  euroloan: 'euroloan',
  extraluotto: 'ekstraluotto',
  'laina.fi': 'lainafi',
  'everyday.fi': 'everyday',
  lainasto: 'lainasto',
  creditstar: 'creditstar',
  'ok money': 'okmoney',
  nordcredit: 'nordcredit',
  saldo: 'saldo',
  vippi: 'vippi',
  'lainaaja.fi': 'lainaaja',
  businesscredit: 'businesscredit',
  'suomen yrityslaina': 'suomenyrityslaina',
  yrityslainat: 'yrityslainat',
  'koin capital': 'koincapital',
  etua: 'etua',
  'freedom rahoitus': 'freedomrahoitus',
}
