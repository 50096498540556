import React from 'react'
import styled from 'styled-components'
import { useImageNames } from '../../../hooks/useImageNames'
import ArticleImage from '../../images/ArticleImage'
import { imageData } from '../../../utils/imageData'

const ToC = styled.ul`
  padding: 1rem;
  border: 1px solid ${props => props.theme.primary};
  list-style: none;
  border-radius: 20px;
  max-width: 400px;
`
const ImageContainer = styled.div``

const TocItem = styled.li`
  padding: 1rem;
  font-weight: 500;
`

const TocHeader = styled.h3`
  padding: 1rem;
`

const Container = styled.div``

export const Content = props => {
  const { headings, htmlAst } = props.data
  const addPhoto = childrenFirstValue => {
    if (imageData[childrenFirstValue.toLowerCase()]) {
      return (
        <ImageContainer>
          <ArticleImage imgName={imageData[childrenFirstValue.toLowerCase()]} />
        </ImageContainer>
      )
    }
  }
  const addInternalLink = childrenFirstValue => (
    <a name={childrenFirstValue.replace(/\s+/g, '')}></a>
  )

  return (
    <Container>
      <TocHeader>Sisällysluettelo</TocHeader>
      <ToC>
        {headings.map(h => (
          <TocItem>
            <a href={`#${h.value.replace(/\s+/g, '')}`}>- {h.value}</a>
          </TocItem>
        ))}
      </ToC>
      {htmlAst.children.map(node => {
        const tagName = node.tagName
        const children = node.children
        const childrenFirstValue = children && children[0].value
        if (!tagName) {
          return ''
        }
        if (tagName.includes('h3')) {
          return (
            <>
              {addInternalLink(childrenFirstValue)}
              <h3>{childrenFirstValue}</h3>
              {addPhoto(childrenFirstValue)}
            </>
          )
        }
        if (tagName.includes('h1')) {
          return <h1>{childrenFirstValue}</h1>
        }
        if (tagName.includes('h2')) {
          return (
            <>
              {addInternalLink(childrenFirstValue)}
              <h2>{childrenFirstValue}</h2>
              {addPhoto(childrenFirstValue)}
            </>
          )
        }
        if (tagName.includes('p')) {
          return <p>{childrenFirstValue}</p>
        }
        if (tagName.includes('h4')) {
          return (
            <>
              {addInternalLink(childrenFirstValue)}
              <h4>{childrenFirstValue}</h4>
              {addPhoto(childrenFirstValue)}
            </>
          )
        }
        if (tagName.includes('ul')) {
          return (
            <ul>
              {children.map(
                child =>
                  child.type.includes('element') && (
                    <li>{child.children[0].value}</li>
                  ),
              )}
            </ul>
          )
        }
        return ''
      })}
    </Container>
  )
}
