import React from 'react'
import styled from 'styled-components'
import { resultCard } from '../../utils/TextConfigure'
import Button from '../buttonComponents/Button'
import LoanCompanyImage from '../images/LoanCompanyImage'
import { device } from '../../utils/responsive'

// Containerit
const Container = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 1px 18px 7px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin: 2rem;
  @media ${device.mobileL} {
    margin: 2rem 0.2rem;
    flex-direction: column;
  }
`
const DataContainer = styled.div`
  display: flex;
  flex: ${props => (props.first ? '3' : '2')};
  flex-direction: column;
  padding: 1rem;
  border-left: ${props =>
    props.first ? '0px solid lightgrey' : '1px solid lightgrey'};
  @media ${device.mobileL} {
    border-left: 0px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }
`

const LowerRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  @media ${device.mobileL} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`
const ExtraContainer = styled.div`
  display: grid;
  justify-self: end;
`
// Fonttien asetukset
const LightFont = styled.div`
  font-weight: 100;
  font-size: 17px;
  padding: 1rem;
`
const BoldFont = styled.div`
  font-weight: 900;
  font-size: 22px;
`

// Logo
const LogoContainer = styled(DataContainer)``
const Image = styled(LoanCompanyImage)``
const LogoText = styled(LightFont)`
  text-transform: capitalize;
`

// Lainasummat
const LoanSumsContainer = styled(DataContainer)``
const LoanTitle = styled(LightFont)``
const LoanSums = styled(BoldFont)``

// Takaisinmaksu
const LoanPaymentContainer = styled(DataContainer)``
const LoanPaymentTitle = styled(LightFont)``
const LoanPayments = styled(BoldFont)``
// Korko
const InterestContainer = styled(DataContainer)``
const InterestTitle = styled(LightFont)``
const Interest = styled(BoldFont)``

// Ikä
const AgeContainer = styled(DataContainer)``
const AgeTitle = styled(LightFont)``
const Age = styled(BoldFont)``

// Hyödyt
const Benefits = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`
const Benefit = styled.div`
  background-color: white;
  color: ${props => props.theme.infoButton};
  padding: 5px 8px;
  border-radius: 5px;
  margin: 1rem;
  font-weight: 600;
`
// Nappula

const ButtonText = styled.div`
  color: white;
  align-self: center;
  justify-self: center;
`
// Muut

const Review = styled.div`
  font-size: 19px;
  font-weight: 600;
  color: darkmagenta;
`

const ResultCard = props => {
  const {
    name,
    minLoan,
    maxLoan,
    minLoanTime,
    maxLoanTime,
    interes,
    age,
    filter,
    benefits,
    link,
  } = props.data.node || props.data
  return (
    <Container>
      <LogoContainer first>
        <Image imgName={filter.toLowerCase()}>Lainaaja</Image>
        <LogoText>{name}</LogoText>
      </LogoContainer>
      <InterestContainer>
        <InterestTitle>Kulut</InterestTitle>
        <Interest>{`${interes}%`}</Interest>
      </InterestContainer>
      <AgeContainer>
        <AgeTitle>Ikäraja</AgeTitle>
        <Age>{`${age} vuotta`}</Age>
      </AgeContainer>
      <LoanPaymentContainer>
        <LoanPaymentTitle>Maksa takaisin(kk)</LoanPaymentTitle>
        <LoanPayments>{`${minLoanTime} - ${maxLoanTime} kk`}</LoanPayments>
      </LoanPaymentContainer>
      <LoanSumsContainer>
        <LoanTitle>Luottoa</LoanTitle>
        <LoanSums>{`${minLoan} - ${maxLoan} €`}</LoanSums>
      </LoanSumsContainer>
      <Button external={true} url={link}>
        <ButtonText>{resultCard.nappula}</ButtonText>
      </Button>

      {/* <LowerRow>
        <Benefits>
          {benefits.map(b => (
            <Benefit key={b}>{b}</Benefit>
          ))}
        </Benefits>
        <ExtraContainer>
        </ExtraContainer>
      </LowerRow> */}
    </Container>
  )
}

export default ResultCard
