import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = styled(Img)`
  max-width: 500px;
`

const ArticleImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      let image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === `${imgName}.jpg`,
      )
      if (!image) {
        image = data.allImageSharp.edges.find(
          edge => edge.node.fluid.originalName === `lainakuvadefault.jpg`,
        )
      }
      return (
        <Image
          fluid={image.node.fluid}
          alt={'Tutustu lainapalveluun ' + imgName}
        />
      )
    }}
  />
)
export default ArticleImage
